import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { graphql } from 'src/gql'
import { ContractFragment, ContractInput, ContractType } from 'src/gql/graphql'

export interface ContractValues {
  name: string
  durationOfMessaging: number | null
  durationOfRewards: number | null
  startingBalance: number
  planYearStartsAt: DateTime | null
  planYearEarningsLimit: number | null
  requiresReferral: boolean
  endsAt: DateTime | null
  rewardsPlanId: string
  type: ContractType | null
  eligibleMemberCount: number | null
  enrollmentStartsAt: DateTime | null
  enrollmentEndsAt: DateTime | null
  notes: string
}

const Query = graphql(`
  query Contracts {
    contracts {
      ...Contract
    }
  }
`)

const Create = graphql(`
  mutation CreateContract($input: ContractInput!) {
    createContractV2(input: $input) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
      }
    }
  }
`)

const Update = graphql(`
  mutation UpdateContract($id: Int!, $input: ContractInput!) {
    updateContract(id: $id, input: $input) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
      }
    }
  }
`)

const UpdateNotes = graphql(`
  mutation UpdateContractNotes($id: Int!, $notes: String!) {
    updateContractNotes(id: $id, notes: $notes) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
      }
    }
  }
`)

export const useContracts = () => {
  const { data } = useQuery(Query)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [create] = useMutation(Create, {
    onCompleted: ({ createContractV2: { message, success, contract } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
      navigate(`/admin/contracts/${(contract as ContractFragment)?.id}`)
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })
  const [update] = useMutation(Update, {
    onCompleted: ({ updateContract: { message, success } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })
  const [updateNotes] = useMutation(UpdateNotes, {
    onCompleted: ({ updateContractNotes: { message, success } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })

  return {
    contracts: data?.contracts ?? [],
    create: async (input: ContractInput) => create({ variables: { input } }),
    update: async (id: number, input: ContractInput) =>
      update({ variables: { id, input } }),
    updateNotes: async (id: number, notes: string) =>
      updateNotes({ variables: { id, notes } }),
  }
}
